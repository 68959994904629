<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1051_360)">
            <path d="M11.4 6C11.7314 6 12.0031 5.73069 11.97 5.40098C11.8722 4.42594 11.5366 3.48642 10.9888 2.66658C10.3295 1.67988 9.39246 0.910849 8.2961 0.456723C7.19974 0.002597 5.99334 -0.116223 4.82946 0.115288C3.66557 0.3468 2.59647 0.918244 1.75736 1.75736C0.918244 2.59647 0.3468 3.66557 0.115288 4.82946C-0.116223 5.99334 0.00259702 7.19974 0.456723 8.2961C0.910849 9.39246 1.67988 10.3295 2.66658 10.9888C3.48642 11.5366 4.42594 11.8722 5.40098 11.97C5.73069 12.0031 6 11.7314 6 11.4C6 11.0686 5.73033 10.8039 5.40155 10.7626C4.66426 10.6699 3.95565 10.4069 3.33326 9.99105C2.54391 9.46362 1.92868 8.71397 1.56538 7.83688C1.20208 6.95979 1.10702 5.99468 1.29223 5.06357C1.47744 4.13246 1.9346 3.27718 2.60589 2.60589C3.27718 1.9346 4.13246 1.47744 5.06357 1.29223C5.99468 1.10702 6.95979 1.20208 7.83688 1.56538C8.71397 1.92868 9.46362 2.54391 9.99105 3.33326C10.4069 3.95565 10.6699 4.66426 10.7626 5.40155C10.8039 5.73033 11.0686 6 11.4 6Z" fill="#71757B" />
        </g>
        <defs>
            <clipPath id="clip0_1051_360">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
